.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    max-width: 100%;
    background: white;
    height: 50px;
    align-items: center;
    margin-bottom: 25px;
    > .left {
        padding-left: 15px;
    }
    > .right {
        padding-right: 15px;
    }
}

.header-drawer .ant-drawer-body {
    flex-direction: column;
    display: flex;
    > a {
        margin-bottom: 25px;
        font-size: 21px;
        color: #333;
    }
}