.user-search {
    // background-color: #b5c2ca;
    min-height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    font-size: 18px;
    color: white;
    padding-top: 50px;

    .search-card {
        width: 100%;
        label {
            font-weight: 500;
        }
        input {
            height: 35px;
        }
        button {
            width: 100%;
            margin-top: 15px;
        }
    }

    .search-results {
        margin-top: 25px;
        width: 100%;


    }
    .action-btn {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}