html {
    min-height: 100%;
    width: 100%;
  }
  body {
    height: 100%;
    font-family: 'Poppins', sans-serif !important;
  
      overflow-x: hidden;
    .ant-form-item-label > label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      padding: 0;
      height: initial;
      margin: 0;
  
      > .required::after {
        display: inline-block;
        content: '*';
        color: red;
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
      min-height: 100%;
    #root {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }